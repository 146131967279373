var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageHeaderBox" }, [
    _c("div", {
      staticClass: "headerBackground",
      style: {
        backgroundImage: `url('${_vm.formartImageUrl(
          _vm.groupInfo.group_header
        )}')`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover"
      }
    }),
    _c("div", { staticClass: "showGroupInfo" }, [
      _c(
        "div",
        { staticClass: "groupCover" },
        [
          _c(
            "el-image",
            {
              staticClass: "coverForGroup",
              attrs: {
                src: _vm.formartImageUrl(_vm.groupInfo.group_header),
                fit: "cover"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "imageLoadError",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { slot: "error" },
                  slot: "error"
                },
                [
                  _c("i", { staticClass: "thumbImg noimg" }, [
                    _vm._v("Melinked")
                  ])
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "firstRowBox" }, [
        _c(
          "div",
          { staticClass: "actionPostBox" },
          [
            _vm.isManager
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.deletLoading,
                        expression: "deletLoading"
                      }
                    ],
                    staticClass: "actionStyle changelikeStatusStyle",
                    attrs: {
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-background": "#FFFFFF"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleDeleteGroup.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass:
                        "iconfont_Me icon-Iconly-Light-Delete-copy iconStyle",
                      staticStyle: { color: "#666", "font-size": "20px" }
                    })
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.likeLoading,
                    expression: "likeLoading"
                  }
                ],
                staticClass: "actionStyle changelikeStatusStyle",
                style: {
                  background: _vm.groupInfo.likeStatus ? "#FFF6F6" : ""
                },
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "#FFFFFF"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.changelikeStatus.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me iconStyle",
                  class: _vm.groupInfo.likeStatus
                    ? "icon-fabulous-fill"
                    : "icon-fabulous",
                  style: { color: _vm.groupInfo.likeStatus ? "#FC4C4C" : "" }
                })
              ]
            ),
            _c(
              "SharePopover",
              {
                attrs: { shareConfig: _vm.shareConfig },
                on: { shareForMelinked: _vm.shareForMelinked }
              },
              [
                _c("div", { staticClass: "actionStyle shareButtonStyle" }, [
                  _c("span", {
                    staticClass: "iconfont_Me icon-share iconStyle"
                  })
                ])
              ]
            ),
            _vm.operatingMenu.length !== 0 &&
            !_vm.isManager &&
            !_vm.isOwnerOfTheGroup
              ? _c(
                  "CommonPopover",
                  {
                    staticClass: "actionStyle otherStyle",
                    attrs: { operatingMenu: _vm.operatingMenu },
                    on: { selectThisMenuItem: _vm.selectThisMenuItem }
                  },
                  [
                    _c("div", { staticClass: "commonPopoverButton" }, [
                      _c("i", { staticClass: "el-icon-more" })
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "secondRowBox" }, [
        _c("div", { staticClass: "leftBox" }, [
          _c("div", { staticClass: "groupName" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.groupInfo.group_name) +
                "\n            "
            ),
            _vm.isOwnerOfTheGroup
              ? _c("span", {
                  staticClass: "iconfont_Me iconStyle icon-qunzhu",
                  style: { color: "#33CC66" }
                })
              : _vm._e(),
            _vm.groupInfo.access_pattern != "3"
              ? _c("span", {
                  staticClass: "iconfont_Me iconStyle",
                  class:
                    _vm.groupInfo.access_pattern == "1"
                      ? "icon-open-fill"
                      : "icon-member-fill",
                  style: {
                    color:
                      _vm.groupInfo.access_pattern == "1"
                        ? "#333333"
                        : "#FFC526"
                  }
                })
              : _vm._e()
          ]),
          _c("div", { staticClass: "groupIdOrTime" }, [
            _c("span", {
              staticClass: "iconfont_Me iconStyle icon-group-fill"
            }),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.groupInfo.members ? _vm.groupInfo.members.length : "0"
                ) +
                "\n          "
            )
          ]),
          _c("div", { staticClass: "groupIdOrTime" }, [
            _c("img", {
              staticClass: "idIcon",
              attrs: {
                src: require("../../../assets/images/newPersonalCenter/timeIcon.png")
              }
            }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.groupInfo.join_time) +
                "\n          "
            )
          ])
        ]),
        _c("div", { staticClass: "rightBox" }, [
          _vm.isMemberOfTheGroup
            ? _c(
                "div",
                {
                  staticClass: "commomButtonHover buttonCommonStyle",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.doSomeThing.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me iconStyle icon-information-fill",
                    style: { color: "#FFFFFF" }
                  })
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "commomButtonHover buttonCommonStyle",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.doSomeThing.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("img", {
                    style: {
                      width: "20px",
                      height: "17px"
                    },
                    attrs: {
                      src: require("../../../assets/images/newEditCenter/addGroupIcon.png")
                    }
                  })
                ]
              )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
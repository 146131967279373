var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "groupIntroduction" }, [
    _vm.tags.length !== 0
      ? _c(
          "div",
          { staticClass: "tagsBox" },
          _vm._l(_vm.tags, function(item, index) {
            return _c("Label", {
              key: index + item,
              style: { marginBottom: "16px" },
              attrs: { labelContent: item, labelIndex: index }
            })
          }),
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "introductionBox" }, [
      _vm._v(_vm._s(_vm.introduction))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="groupMember">
    <div class="groupMemberHeader">
      <span class="iconfont_Me icon-group iconStyle"></span>
      <span class="countText">({{members?members.length:'0'}})</span>
    </div>
    <div class="memberBoxOuter">
      <div class="memberBox" :style="{maxHeight:isExpand?'':'240px'}">
        <!-- 内部盒子主要是用于判断当前展示群成员的盒子的高度 -->
        <div class="memberInnerBox" ref="memberInnerBox">
          <div class="memberAvatar" v-for="(item,index) in members">
            <Avatar 
              :path="formartImageUrl(item.profile_photo)" 
              :international="item.country?Number(item.country):0" 
              :isOwnerOfTheGroup="item.isOwnerOfTheGroup"
              :providerId="item.provider_id"
            ></Avatar>
          </div>
        </div>
      </div>
      <div class="operatingLine" v-if="needShowExpandButton">
        <span @click.stop="changeIsExpandStatus" class="iconfont_Me iconStyle" :class="isExpand?'icon-up':'icon-down'"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "../../components/avatarDisplay";
export default {
  name: "groupMember",
  components: {
    Avatar
  },
  data() {
    return {
      // 是否需要展示下拉或收起按钮
      needShowExpandButton: true,
      // 是否已展开
      isExpand: false
    }
  },
  props: {
    // 用户的详细信息
    groupInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    // 群成员
    members() {
      let members = this.groupInfo.members;
      if(members && members.length !== 0) {
        members = members.map(item => {
          item.isOwnerOfTheGroup = false;
          if(this.groupInfo.group_owner === item.provider_id ) {
            item.isOwnerOfTheGroup = true;
          }
          return item;
        })
      }
      return members;
    },
  },
  updated(){
    this.$nextTick(()=>{
      if(this.$refs.memberInnerBox.offsetHeight > 240) {
        this.needShowExpandButton = true;
      }else {
        this.needShowExpandButton = false;
      }
    })
  },
  methods: {
    changeIsExpandStatus() {
      this.isExpand = !this.isExpand;
    }
  }
}
</script>
<style lang="stylus" scoped>
  .groupMember
    width 100%;
    min-height 0;
    overflow hidden;
    background #FFFFFF;
    margin-top 10px;
    .groupMemberHeader
      width 100%;
      height 48px;
      display flex;
      padding 0 40px;
      align-items center;
      box-sizing border-box;
      justify-content flex-start;
      border-bottom 1px solid #F0F0F0;
      .iconStyle
        font-size 24px;
        color #343434;
        margin-right 10px;
      .countText
        font-size 14px;
        color #8F8F8F;
    .memberBoxOuter
      width 100%;
      min-height 0;
      padding 10px 40px 20px 40px;
      overflow hidden;
      box-sizing border-box;
      .memberBox
        width 100%;
        overflow hidden;
        .memberInnerBox
          width 100%;
          overflow hidden;
          .memberAvatar
            width 50px;
            height 50px;
            display inline-block;
            margin 8px 8px 0 0;
      .operatingLine
        width 100%;
        height 20px;
        margin-top 20px;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          font-size 16px;
          color #8F8F8F;
          cursor pointer;
        

</style>
<template>
  <div class="pageHeaderBox">
    <div class="headerBackground" :style="{
      backgroundImage: `url('${formartImageUrl(groupInfo.group_header)}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'center',
      backgroundSize: 'cover',
    }"></div>
    <div class="showGroupInfo">
      <div class="groupCover">
        <el-image :src="formartImageUrl(groupInfo.group_header)" class="coverForGroup" fit="cover">
          <div slot="error" class="imageLoadError" style="width: 100%; height: 100%">
            <i class="thumbImg noimg">Melinked</i>
          </div>
        </el-image>
      </div>
      <div class="firstRowBox">
        <div class="actionPostBox">
          <!-- 删除群 -->
          <div class="actionStyle changelikeStatusStyle" @click.stop="handleDeleteGroup" v-if="isManager"
            v-loading="deletLoading" element-loading-spinner="el-icon-loading" element-loading-background="#FFFFFF">
            <i class="iconfont_Me icon-Iconly-Light-Delete-copy iconStyle" style="color: #666;font-size:20px"></i>
          </div>

          <!-- 点赞 -->
          <div class="actionStyle changelikeStatusStyle" @click.stop="changelikeStatus"
            :style="{ background: groupInfo.likeStatus ? '#FFF6F6' : '' }" v-loading="likeLoading"
            element-loading-spinner="el-icon-loading" element-loading-background="#FFFFFF">
            <span class="iconfont_Me iconStyle" :class="
              groupInfo.likeStatus ? 'icon-fabulous-fill' : 'icon-fabulous'
            " :style="{ color: groupInfo.likeStatus ? '#FC4C4C' : '' }"></span>
          </div>
          <SharePopover :shareConfig="shareConfig" @shareForMelinked="shareForMelinked">
            <div class="actionStyle shareButtonStyle">
              <span class="iconfont_Me icon-share iconStyle"></span>
            </div>
          </SharePopover>

          <CommonPopover class="actionStyle otherStyle"
            v-if="operatingMenu.length !== 0 && !isManager && !isOwnerOfTheGroup" :operatingMenu="operatingMenu"
            @selectThisMenuItem="selectThisMenuItem">
            <div class="commonPopoverButton">
              <i class="el-icon-more"></i>
            </div>
          </CommonPopover>
        </div>
      </div>

      <div class="secondRowBox">
        <div class="leftBox">
          <div class="groupName">
            {{ groupInfo.group_name }}
            <span class="iconfont_Me iconStyle icon-qunzhu" :style="{ color: '#33CC66' }"
              v-if="isOwnerOfTheGroup"></span>
            <span v-if="groupInfo.access_pattern != '3'" class="iconfont_Me iconStyle" :class="
              groupInfo.access_pattern == '1'
                ? 'icon-open-fill'
                : 'icon-member-fill'
            " :style="{
  color: groupInfo.access_pattern == '1' ? '#333333' : '#FFC526',
}"></span>
          </div>
          <div class="groupIdOrTime">
            <span class="iconfont_Me iconStyle icon-group-fill"></span>
            {{ groupInfo.members ? groupInfo.members.length : "0" }}
          </div>
          <div class="groupIdOrTime">
            <img class="idIcon" :src="
              require('../../../assets/images/newPersonalCenter/timeIcon.png')
            " />
            {{ groupInfo.join_time }}
          </div>
        </div>
        <div class="rightBox">
          <div @click.stop="doSomeThing" class="commomButtonHover buttonCommonStyle" v-if="isMemberOfTheGroup">
            <span class="iconfont_Me iconStyle icon-information-fill" :style="{ color: '#FFFFFF' }"></span>
          </div>
          <div @click.stop="doSomeThing" class="commomButtonHover buttonCommonStyle" v-else>
            <img :src="
              require('../../../assets/images/newEditCenter/addGroupIcon.png')
            " :style="{
  width: '20px',
  height: '17px',
}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changelikeStatus } from "@/api/newVersion";
import { joinGroup } from "@/api/newVersion/groupCenter";
import SharePopover from "../../components/sharePopover";
import CommonPopover from "../../components/commonPopover";
export default {
  name: "groupCenterHeader",
  components: {
    SharePopover,
    CommonPopover,
  },
  props: {
    // 用户的详细信息
    groupInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 改变喜欢的加载状态
      likeLoading: false,
      //删除操作
      deletLoading: false,
      // 群组类型
      accessPattern: 1,
      // 正在加入群，禁止再点击
      isJoining: false,
    };
  },
  computed: {
    // 分享所需要的参数
    shareConfig() {
      return {
        // 分享的内容类型(1:帖子,3:用户,4:群)
        share_type: 4,
        // 分享的内容Id
        share_id: this.groupInfo.group_id,
      };
    },
    // 当前用户是否是这个群的成员
    isMemberOfTheGroup() {
      let isMemberOfTheGroup = false;
      if (
        this.$store.getters.userInfo &&
        this.$store.getters.userInfo.id &&
        this.groupInfo.members &&
        this.groupInfo.members.length !== 0
      ) {
        isMemberOfTheGroup = this.groupInfo.members.some(
          (item) => item.provider_id == this.$store.getters.userInfo.id
        );
      }
      return isMemberOfTheGroup;
    },
    // 当前用户是否是这个群的群主
    isOwnerOfTheGroup() {
      let isOwnerOfTheGroup = false;
      if (
        this.$store.getters.userInfo &&
        this.$store.getters.userInfo.id &&
        this.groupInfo.group_owner === this.$store.getters.userInfo.id
      ) {
        isOwnerOfTheGroup = true;
      }
      return isOwnerOfTheGroup;
    },
    // isSelf() {
    //   return this.personalInfo.providerId === this.$store.getters.userInfo.id;
    // },
    //是否是管理员
    isManager() {
      if (this.$store.getters.userInfo.manager_role == 1) {
        return true;
      } else {
        return false;
      }
    },
    operatingMenu() {
      let operatingMenu = [
        {
          iconClassName: "iconfont_Me icon-a-zu5510",
          operatingFn: this.collectionAction,
          tooltipContent: this.$t('collection')
        },
      ];
      return operatingMenu;
    },
  },
  methods: {
    // 分享帖子信息到IM
    shareForMelinked(shareData) {
      // 重置分享信息
      let sharingDataInfo = {
        // 分享的标题
        title: shareData.shareTitle,
        // 分享展示图片
        picurl: shareData.shareImageSrc,
        // 分享展示内容
        briefInfo: shareData.summary,
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        serviceType: "group",
        // 分享对象的唯一ID
        uuid: this.groupInfo.group_id,
      };
      this.$store.dispatch("commitOpenSharePopup", sharingDataInfo);
    },
    // 改变喜欢状态
    async changelikeStatus() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let params = {
        userId: this.$store.getters.userInfo.id,
        language: localStorage.getItem("langCode"),
        action: !this.groupInfo.likeStatus,
        type: 4,
      };
      this.likeLoading = true;
      let result = await changelikeStatus(this.groupInfo.group_id, params);
      this.likeLoading = false;
      if (result.code === 201) {
        this.$emit("refreshGroupInfo");
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    handleDeleteGroup() {
      this.$store.commit("setDeleteType", "group");
      this.$store.commit("setDeleteGroupId", this.groupInfo.group_id);
      this.$store.commit("setDeleteDialogVisible", true);
      this.deletLoading = true;
      setTimeout(() => {
        this.deletLoading = false;
      }, 1000);
    },
    // 根据状态作某些操作
    async doSomeThing() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      if (this.isMemberOfTheGroup) {
        await this.startChatting();
      } else {
        // 如果是公开群，直接发起进群申请
        if (this.groupInfo.access_pattern === "1" && !this.isJoining) {
          this.isJoining = true;
          let params = {
            groupId: this.groupInfo.group_id,
            userId: this.$store.getters.userInfo.id,
            type: 0,
            tipContent: "",
          };
          let result = await joinGroup(params);
          if (result.code == 200) {
            this.$emit("refreshGroupInfo");
          } else {
            this.$message({
              type: "error",
              message: this.$t("sys_error"),
            });
          }
          this.isJoining = false;
        } else if (this.groupInfo.access_pattern !== "1") {
          // 需要判断是公开群还是会员群，公开群不需要验证，直接发起加群请求
          this.addGroupVerification();
        }
      }
    },
    // 加群验证弹窗
    addGroupVerification() {
      let verificationData = {
        groupId: this.groupInfo.group_id,
        profile_photo: this.groupInfo.group_header,
        name: this.groupInfo.group_name,
      };
      this.$store.dispatch("commitOpenJoinGroupPopup", verificationData);
    },
    // 开始群聊
    async startChatting() {
      this.chatWithAuthor(
        {
          thumb: this.formartImageUrl(this.groupInfo.group_header),
          name: this.groupInfo.group_name,
          id: this.groupInfo.group_id,
        },
        "group"
      );
    },
    selectThisMenuItem(index) {
      if (index == 0) {
        //投诉处理
        this.$store.commit("setComplaintType", 4);
        this.$store.commit("setComplaintId", this.groupInfo.group_id);
        this.$store.commit("setGroupInfo", this.groupInfo);
        this.$store.commit("setComplaintDialogVisible", true);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.pageHeaderBox {
  width: 100%;
  overflow: hidden;

  .headerBackground {
    width: 100%;
    height: 140px;
    filter: blur(30px);
  }

  .showGroupInfo {
    width: 100%;
    height: 226px;
    background: #FFFFFF;
    position: relative;

    .groupCover {
      width: 246px;
      height: 126px;
      overflow: hidden;
      top: -46px;
      left: 40px;
      position: absolute;

      .coverForGroup {
        width: 100%;
        height: 126px;
        border-radius: 6px;

        .imageLoadError {
          .thumbImg {
            width: 100%;
            height: 100%;
            user-select: none;
            display: inline-block;
            background: #eee;
            font-style: normal;
            color: #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 800;
            font-size: 30px;
          }
        }
      }
    }

    .firstRowBox {
      width: 100%;
      height: 40px;
      padding-top: 30px;
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .actionPostBox {
        height: 40px;
        margin-right: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .actionStyle {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background: #F7F7F7;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s, background 0.3s;

          .iconStyle {
            color: #666666;
            font-size: 22px;
            transition: color 0.3s;
          }

          &:active {
            transform: scale(1.1);
          }
        }

        .changelikeStatusStyle {
          margin-right: 40px;

          &:hover {
            background: #FFF6F6;

            .iconStyle {
              color: #FC4C4C;
            }
          }
        }

        .shareButtonStyle {
          &:hover {
            background: #F2FCF8;

            .iconStyle {
              color: #33CC66;
            }
          }
        }

        .otherStyle {
          margin-left: 40px;

          .el-icon-more {
            color: #666;
            transform: rotate(90deg);
          }
        }
      }
    }

    .secondRowBox {
      left: 0;
      bottom: 20px;
      width: 100%;
      position: absolute;
      padding: 0 25px 0 40px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .leftBox {
        flex: 1;
        min-height: 0;
        min-width: 0;
        overflow: hidden;

        .groupName {
          width: 100%;
          height: 24px;
          font-size: 18px;
          font-weight: bold;
          color: #373737;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .iconStyle {
            font-size: 18px;
            margin-left: 10px;
          }
        }

        .groupIdOrTime {
          width: 100%;
          height: 24px;
          font-size: 14px;
          color: #8F8F8F;
          margin-top: 10px;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .idIcon {
            width: 14px;
            height: 14px;
            overflow: hidden;
            margin-right: 10px;
          }

          .iconStyle {
            color: #8F8F8F;
            font-size: 18px;
            margin-right: 10px;
          }
        }
      }

      .rightBox {
        width: 220px;
        min-height: 40px;
        flex-shrink: 0;
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        justify-content: space-between;

        .buttonCommonStyle {
          width: 107px;
          height: 40px;
          border-radius: 6px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          .iconStyle {
            font-size: 24px;
          }
        }

        .editButton {
          cursor: pointer;
          background: #E6E6E6;
          transition: background 0.3s;

          &:hover {
            background: #F0F0F0;
          }

          .iconStyle {
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
.pageHeaderBox {
  .firstRowBox {
    .actionPostBox {
      .el-loading-spinner {
        margin-top: -10px;

        .circular {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.coverForGroup {
  &:hover {
    .el-image__inner {
      transform: scale(1.1) !important;
    }
  }

  .el-image__inner {
    transform: scale(1);
    transition: transform 0.3s;
  }
}
</style>
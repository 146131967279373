<template>
  <div
    class="centerPage"
    v-infinite-scroll="startLoadData"
    infinite-scroll-disabled="loadDataDisabled"
    infinite-scroll-distance="10"
    infinite-scroll-immediate-check="false"
  >
    <div class="centerPageContent" v-if="groupInfo.is_delete == 1">
      <GroupCenterHeader
        :groupInfo="groupInfo"
        @refreshGroupInfo="refreshGroupInfo"
      ></GroupCenterHeader>
      <div class="contentBottom">
        <div class="leftSidebar">
          <GroupIntroduction
            v-if="groupInfo.group_tag !== '' || groupInfo.group_info !== ''"
            :groupInfo="groupInfo"
          >
          </GroupIntroduction>
          <GroupMember :groupInfo="groupInfo"></GroupMember>
          <div class="commentAreaBox">
            <CommentArea
              :groupInfo="groupInfo"
              :needScroll="true"
            ></CommentArea>
          </div>
        </div>
        <div class="rightSidebar">
          <div class="recommendedGroupBox">
            <RecommendedGroup
              :recommendedList="recommendedList"
              @loadMoreRecommendedData="loadMoreRecommendedData"
            >
            </RecommendedGroup>
          </div>
          <MelinkedTips></MelinkedTips>
        </div>
      </div>
    </div>
    <!-- 群已經被删除显示下面的页面 -->
    <div v-else class="centerPageContent">
      <div class="detailsPage no-data">
        <div class="detailsPageContent" style="flex-direction: column">
          <div class="detailsContent">
            <transition name="fade">
              <div
                class="contentHeader"
                :style="{
                  height: isScrollUp ? '40px' : '0px',
                  opacity: isScrollUp ? '1' : '0',
                }"
              >
                <span
                  class="iconfont_Me icon-left iconStyle1"
                  @click.stop="goBackToHomePage"
                ></span>
                <span
                  class="iconfont_Me icon-home-fill iconStyle2"
                  @click.stop="goBackToHomePage"
                ></span>
              </div>
            </transition>
            <div class="no-data-wrap">
              <img
                class="no-data-img"
                src="../../../assets/images/no-data.png"
                alt=""
              />
              <div>{{ $t("nogrop") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GroupMember from "./groupMember";
import CommentArea from "./commentArea";
import RecommendedGroup from "./recommendedGroup";
import GroupIntroduction from "./groupIntroduction";
import GroupCenterHeader from "./groupCenterHeader";
import MelinkedTips from "../../components/melinkedTips";
import {
  getRecommendGroup,
  getGroupDetail,
} from "@/api/newVersion/groupCenter";
import { getGroupLikeList } from "@/api/newVersion";
export default {
  name: "groupCenter",
  components: {
    GroupCenterHeader,
    MelinkedTips,
    GroupIntroduction,
    GroupMember,
    CommentArea,
    RecommendedGroup,
  },
  data() {
    return {
      // 正在加载中,禁止再触发
      loadDataDisabled: false,
      // 群组ID
      groupId: "",
      // 群组信息
      groupInfo: {},
      // 点赞成员列表
      likeProviders: [],
      // 推荐列表组件
      recommendedList: [],
      // 推荐列表的页码
      recommendedPageNum: 0,
      isScrollUp: true,
    };
  },
  watch: {
    // 监听群组ID变化
    "$route.params.id": {
      async handler(val, old) {
        if (val !== old && this.$route.name === "groupCenter") {
          this.groupId = this.$Base64.decode(val.replace("pai_", ""));
          this.recommendedPageNum = 0;
          this.recommendedList = [];
          await this.getGroupInfoById();
          await this.loadMoreRecommendedData();
        }
      },
      deep: true,
    },
  },
  async created() {
    // 如果没有带ID过来，就强制跳转到主页
    if (this.$route.params.id) {
      this.groupId = this.$Base64.decode(
        this.$route.params.id.replace("pai_", "")
      );
      this.recommendedPageNum = 0;
      this.recommendedList = [];
      await this.refreshGroupInfo();
      await this.loadMoreRecommendedData();
    } else {
      this.routeJump({ name: "home" });
    }
  },
  methods: {
    // 开始触发加载新数据(这里主要是用于将事件传递给子组件，让子组件触发)
    async startLoadData() {
      this.loadDataDisabled = true;
    },
    // 刷新群详细信息
    async refreshGroupInfo() {
      await this.getGroupLikeList();
      await this.getGroupInfoById();
    },
    // 获取群组信息
    async getGroupInfoById() {
      let params = {
        groupId: this.groupId,
        userId: this.$store.getters.userInfo.id || "",
      };
      let result = await getGroupDetail(params);
      if (result.code == 1) {
        this.groupInfo = {
          ...result.data[0],
          likeStatus: result.data[0].like_id ? true : false,
          likeProviders: this.likeProviders,
        };
      } else {
        this.$message({
          type: "error",
          message: result.message || this.$t("nogrop"),
        });
      }
    },
    // 获取群的点赞列表
    async getGroupLikeList() {
      let params = {
        language: localStorage.getItem("langCode") || 1,
        type: 4,
      };
      let result = await getGroupLikeList(this.groupId, params);
      if (result.code == 200) {
        if (result.data.likeList.length !== 0) {
          this.likeProviders = result.data.likeList;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 加载更多推荐群组数据
    loadMoreRecommendedData() {
      return new Promise(async (resolve, reject) => {
        this.recommendedPageNum++;
        let params = {
          pageNum: this.recommendedPageNum,
          pageSize: 3,
          id: this.groupId,
        };
        let result = await getRecommendGroup(this.groupId, params);
        if (result.code === 200) {
          if (result.data.data.length !== 0) {
            this.recommendedList = this.recommendedList.concat(
              result.data.data
            );
          } else {
            this.recommendedPageNum--;
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
    // 返回主页
    goBackToHomePage() {
      this.routeJump({ name: "home" });
    },
  },
};
</script>
<style lang="stylus" scoped>
.centerPage {
  width: 100%;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #F7F7F7;
  overflow-x: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .centerPageContent {
    width: 1160px;
    min-height: 100%;
    margin: auto;

    .contentBottom {
      width: 100%;
      margin-top: 10px;
      min-height: 60px;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .leftSidebar {
        flex: 1;
        min-width: 0;
        min-height: 60px;
        overflow: hidden;

        .commentAreaBox {
          margin-top: 10px;
          padding: 20px 30px;
          background: #FFFFFF;
        }
      }

      .rightSidebar {
        width: 278px;
        overflow: hidden;
        min-height: 114px;
        flex-shrink: 0;
        margin-left: 16px;

        .recommendedGroupBox {
          width: 100%;
          min-height: 0;
          overflow: hidden;
          background: #FFFFFF;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.no-data {
  .detailsPageContent {
    align-items: center !important;

    .detailsContent {
      .contentHeader {
        border-bottom: 10px solid #f7f7f7 !important;
      }
    }
  }

  .no-data-wrap {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 542px;
    flex-direction: column;
    font-size: 14px;
    color: #ccc;

    .no-data-img {
      width: 95px;
      height: 62px;
      margin-bottom: 10px;
    }
  }
}

.detailsPage {
  // width: 100vw;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #F7F7F7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  .detailsPageContent {
    width: 1160px;
    height: 100%;
    overflow: hidden;
    display: flex;
    margin-top: 15px;
    align-items: flex-start;
    justify-content: space-between;

    .detailsContent {
      width: 866px;
      max-height: 100%;
      background: #ffffff;

      .contentHeader {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #F0F0F0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: height 0.3s, opacity 0.3s;

        .iconStyle1 {
          font-size: 22px;
          color: #333333;
          margin-right: 8px;
          cursor: pointer;
        }

        .iconStyle2 {
          font-size: 22px;
          color: #8F8F8F;
          cursor: pointer;
        }
      }

      .contentBox {
        width: 100%;
        padding: 24px 30px;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        transition: height 0.3s;
        scrollbar-width: none;
        position: relative;

        &::-webkit-scrollbar {
          width: 0px;
        }

        .opration {
          position: absolute;
          right: 30px;
          top: 24px;
        }

        .commonPopoverStyle {
          position: absolute;
          top: 20px;
          right: 30px;
          z-index: 2;

          .commonPopoverButton {
            width: 24px;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .containerForStyle {
          width: 100%;
          box-sizing: border-box;
          padding-left: 66px;

          .labelRow {
            width: 100%;
            height: auto;
            line-height: 40px;
            overflow: hidden;
          }
        }

        .actionPostBox {
          height: 60px;
          width: 100%;
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          .actionStyle {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background: #F7F7F7;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s, background 0.3s;

            .iconStyle {
              color: #666666;
              font-size: 32px;
              transition: color 0.3s;
            }

            &:active {
              transform: scale(1.1);
            }
          }

          .changelikeStatusStyle {
            margin-right: 25px;

            &:hover {
              background: #FFF6F6;

              .iconStyle {
                color: #FC4C4C;
              }
            }
          }

          .shareButtonStyle {
            &:hover {
              background: #F2FCF8;

              .iconStyle {
                color: #33CC66;
              }
            }
          }
        }

        .intervalLine {
          width: 100%;
          height: 1px;
          margin-top: 30px;
          background: #F0F0F0;
        }
      }
    }
  }
}
</style>

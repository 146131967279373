var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "groupMember" }, [
    _c("div", { staticClass: "groupMemberHeader" }, [
      _c("span", { staticClass: "iconfont_Me icon-group iconStyle" }),
      _c("span", { staticClass: "countText" }, [
        _vm._v("(" + _vm._s(_vm.members ? _vm.members.length : "0") + ")")
      ])
    ]),
    _c("div", { staticClass: "memberBoxOuter" }, [
      _c(
        "div",
        {
          staticClass: "memberBox",
          style: { maxHeight: _vm.isExpand ? "" : "240px" }
        },
        [
          _c(
            "div",
            { ref: "memberInnerBox", staticClass: "memberInnerBox" },
            _vm._l(_vm.members, function(item, index) {
              return _c(
                "div",
                { staticClass: "memberAvatar" },
                [
                  _c("Avatar", {
                    attrs: {
                      path: _vm.formartImageUrl(item.profile_photo),
                      international: item.country ? Number(item.country) : 0,
                      isOwnerOfTheGroup: item.isOwnerOfTheGroup,
                      providerId: item.provider_id
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ]
      ),
      _vm.needShowExpandButton
        ? _c("div", { staticClass: "operatingLine" }, [
            _c("span", {
              staticClass: "iconfont_Me iconStyle",
              class: _vm.isExpand ? "icon-up" : "icon-down",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.changeIsExpandStatus.apply(null, arguments)
                }
              }
            })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="recommendedBox">
    <div class="recommendeItemBox">
      <div 
        class="recommendeItem" 
        v-for="(item,index) in recommendedList"
        :key="item.pkId"
        @click.stop="jumpNewPage(index)"
      >
        <div class="groupCover">
          <el-image
            :src="formartImageUrl(item.contentPic)"
            class="imageForGroup"
            fit="cover">
            <div slot="error" class="imageLoadError" style="width:100%;height:100%">
              <i class="thumbImg noimg">Melinked</i>
            </div>
          </el-image>
        </div>
        <div class="groupInformation">
          <div class="groupName">
            <div class="nameBox">{{item.subject}}</div>
            <div class="iconBox">
              <span class="iconfont_Me iconStyle" :class="accessPattern == 1?'icon-open-fill':'icon-member-fill'" :style="{color:accessPattern == 1?'#333333':'#FFC526'}"></span>
            </div>
          </div>
          <div class="groupContent">{{item.content}}</div>
        </div>
      </div>
    </div>
    <div class="loadMordButton" @click.stop="loadMoreRecommendedData">
      <img :style="{width:'16px'}" :src="require('../../../assets/images/newPersonalCenter/openPopoverIcon.png')">
    </div>
  </div>
</template>
<script>
export default {
  name: "recommendedGroup",
  props: {
    // 推荐列表
    recommendedList: {
      type: Array,
      default:()=>{
        return []
      }
    }
  },
  data() {
    return {
      accessPattern: 2
    }
  },
  methods: {
    // 加载更多推荐数据
    loadMoreRecommendedData() {
      this.$emit("loadMoreRecommendedData")
    },
    // 进入新的群组信息页面
    jumpNewPage(index) {
      this.routeJump({
        name: 'groupCenter', 
        params: {
          id : this.recommendedList[index].pkId,
        } 
      });
    }
  }
  
}
</script>
<style lang="stylus" scoped>
  .recommendedBox
    width 100%;
    .recommendeItemBox
      width 100%;
      min-height 0;
      padding 0 20px;
      overflow hidden;
      box-sizing border-box;
      .recommendeItem
        width 100%;
        padding 20px 0;
        cursor pointer;
        box-sizing border-box;
        border-bottom 1px solid #F0F0F0;
        display flex;
        align-items center;
        justify-content flex-start;
        .groupCover
          width 73px;
          height 73px;
          flex-shrink 0;
          margin-right 14px;
          overflow hidden;
          border-radius 5px;
          .imageForGroup
            width 100%;
            height 100%;
            .imageLoadError
              .thumbImg
                width 100%;
                height 100%;
                user-select none;
                display inline-block;
                background: #eee;
                font-style: normal;
                color: #ddd;
                display flex;
                align-items center;
                justify-content center;
                font-weight: 800;
                font-size: 16px;
        .groupInformation
          flex 1;
          min-width 0;
          height 73px;
          display flex;
          flex-flow column;
          align-items flex-start;
          justify-content flex-start;
          .groupName
            width 100%;
            display flex;
            align-items center;
            justify-content flex-start;
            .nameBox
              max-width 100%;
              font-size 14px;
              color #373737;
              font-weight bold;
              overflow hidden;
              text-overflow ellipsis;
              white-space nowrap;
            .iconBox
              height 18px;
              width 18px;
              margin-left 8px;
              flex-shrink 0;
              .iconStyle
                font-size 18px;
          .groupContent
            margin-top 10px;
            font-size 12px;
            line-height 20px;
            color #666666;
            display -webkit-box;
            word-break break-all;
            -webkit-box-orient vertical;
            -webkit-line-clamp 2;
            overflow hidden;
    .loadMordButton
      width 100%;
      height 30px;
      cursor pointer;
      background #FFFFFF;
      transition background .3s;
      box-shadow: 0px 3px 6px 0px rgba(204,204,204,0.16); 
      display flex;
      align-items center;
      justify-content center;
      &:hover
        background #F0F0F0

</style>
<style lang="stylus">
  .imageForGroup
    &:hover
      .el-image__inner
        transform scale(1.1) !important;
    .el-image__inner
      transform scale(1);
      transition transform .3s;

</style>
<template>
  <div class="groupIntroduction">
    <div class="tagsBox" v-if="tags.length !== 0">
      <Label 
        :style="{marginBottom: '16px'}"
        v-for="(item,index) in tags" 
        :labelContent="item" 
        :labelIndex="index" 
        :key="index+item"
      ></Label>
    </div>
    <div class="introductionBox">{{introduction}}</div>
  </div>
</template>
<script>
import Label from "../../components/label";
export default {
  name: "groupIntroduction",
  components: {
    Label
  },
  props: {
    // 用户的详细信息
    groupInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    // 群标签
    tags() {
      let tags = [];
      if(this.groupInfo.group_tag && this.groupInfo.group_tag !== '') {
        tags = this.groupInfo.group_tag.split(",");
      }
      return tags;
    },
    // 群描述
    introduction() {
      let groupInfo = this.groupInfo.group_info;
      if(groupInfo && groupInfo !== "") {
        groupInfo = groupInfo.replace(/<[^>]+>/g,"");
      }
      return groupInfo;
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="stylus" scoped>
  .groupIntroduction
    width 100%;
    min-height 0;
    overflow hidden;
    padding 30px 40px;
    box-sizing border-box;
    background #FFFFFF;
    .tagsBox
      width 100%;
      min-height 0;
      overflow hidden;
      margin-bottom 4px;
    .introductionBox
      width 100%;
      font-size 14px;
      line-height 24px;
      color #666666;
      text-align left;

</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "recommendedBox" }, [
    _c(
      "div",
      { staticClass: "recommendeItemBox" },
      _vm._l(_vm.recommendedList, function(item, index) {
        return _c(
          "div",
          {
            key: item.pkId,
            staticClass: "recommendeItem",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.jumpNewPage(index)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "groupCover" },
              [
                _c(
                  "el-image",
                  {
                    staticClass: "imageForGroup",
                    attrs: {
                      src: _vm.formartImageUrl(item.contentPic),
                      fit: "cover"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "imageLoadError",
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { slot: "error" },
                        slot: "error"
                      },
                      [
                        _c("i", { staticClass: "thumbImg noimg" }, [
                          _vm._v("Melinked")
                        ])
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c("div", { staticClass: "groupInformation" }, [
              _c("div", { staticClass: "groupName" }, [
                _c("div", { staticClass: "nameBox" }, [
                  _vm._v(_vm._s(item.subject))
                ]),
                _c("div", { staticClass: "iconBox" }, [
                  _c("span", {
                    staticClass: "iconfont_Me iconStyle",
                    class:
                      _vm.accessPattern == 1
                        ? "icon-open-fill"
                        : "icon-member-fill",
                    style: {
                      color: _vm.accessPattern == 1 ? "#333333" : "#FFC526"
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "groupContent" }, [
                _vm._v(_vm._s(item.content))
              ])
            ])
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "loadMordButton",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.loadMoreRecommendedData.apply(null, arguments)
          }
        }
      },
      [
        _c("img", {
          style: { width: "16px" },
          attrs: {
            src: require("../../../assets/images/newPersonalCenter/openPopoverIcon.png")
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.startLoadData,
          expression: "startLoadData"
        }
      ],
      staticClass: "centerPage",
      attrs: {
        "infinite-scroll-disabled": "loadDataDisabled",
        "infinite-scroll-distance": "10",
        "infinite-scroll-immediate-check": "false"
      }
    },
    [
      _vm.groupInfo.is_delete == 1
        ? _c(
            "div",
            { staticClass: "centerPageContent" },
            [
              _c("GroupCenterHeader", {
                attrs: { groupInfo: _vm.groupInfo },
                on: { refreshGroupInfo: _vm.refreshGroupInfo }
              }),
              _c("div", { staticClass: "contentBottom" }, [
                _c(
                  "div",
                  { staticClass: "leftSidebar" },
                  [
                    _vm.groupInfo.group_tag !== "" ||
                    _vm.groupInfo.group_info !== ""
                      ? _c("GroupIntroduction", {
                          attrs: { groupInfo: _vm.groupInfo }
                        })
                      : _vm._e(),
                    _c("GroupMember", { attrs: { groupInfo: _vm.groupInfo } }),
                    _c(
                      "div",
                      { staticClass: "commentAreaBox" },
                      [
                        _c("CommentArea", {
                          attrs: { groupInfo: _vm.groupInfo, needScroll: true }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "rightSidebar" },
                  [
                    _c(
                      "div",
                      { staticClass: "recommendedGroupBox" },
                      [
                        _c("RecommendedGroup", {
                          attrs: { recommendedList: _vm.recommendedList },
                          on: {
                            loadMoreRecommendedData: _vm.loadMoreRecommendedData
                          }
                        })
                      ],
                      1
                    ),
                    _c("MelinkedTips")
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _c("div", { staticClass: "centerPageContent" }, [
            _c("div", { staticClass: "detailsPage no-data" }, [
              _c(
                "div",
                {
                  staticClass: "detailsPageContent",
                  staticStyle: { "flex-direction": "column" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "detailsContent" },
                    [
                      _c("transition", { attrs: { name: "fade" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "contentHeader",
                            style: {
                              height: _vm.isScrollUp ? "40px" : "0px",
                              opacity: _vm.isScrollUp ? "1" : "0"
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont_Me icon-left iconStyle1",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.goBackToHomePage.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            }),
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-home-fill iconStyle2",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.goBackToHomePage.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "no-data-wrap" }, [
                        _c("img", {
                          staticClass: "no-data-img",
                          attrs: {
                            src: require("../../../assets/images/no-data.png"),
                            alt: ""
                          }
                        }),
                        _c("div", [_vm._v(_vm._s(_vm.$t("nogrop")))])
                      ])
                    ],
                    1
                  )
                ]
              )
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }